// ============================
// RESETS
// ============================
table, thead, tbody, tfoot, tr, th, td {
	margin: 0;
	padding: 0;
	border: none;
	border-collapse: inherit;
	border-spacing: 0;
	border-color: inherit;
	vertical-align: inherit;
	font-weight: inherit;
	-webkit-border-horizontal-spacing: 0;
	-webkit-border-vertical-spacing: 0; }

html, body, h1, h2, h3, h4, h5, h6, div, ul, ul li, ol, ol li,
input, label, textarea, select, option, button, span,
p, select, header, footer, nav, i, b, br, hr, article {
	margin: 0;
	padding: 0; }

ul, ol {
	list-style-type: none; }


a {
	text-decoration: none;
	cursor: pointer; }

input:focus,
label:focus,
textarea:focus,
select:focus,
option:focus,
button:focus,
a:focus {
	outline: none; }

*, *:before, *:after, input, textarea, button, select {
	box-sizing: border-box; }

// ============================
// FONTS
// ============================

@font-face {
	font-family: "Montserrat";
	src: url("../fonts/Montserrat-Regular.ttf") format("truetype");
	font-weight: normal;
	font-style: normal; }

@font-face {
	font-family: "Montserrat";
	src: url("../fonts/Montserrat-Medium_0.ttf") format("truetype");
	font-weight: 500;
	font-style: normal; }

// ============================
// MAIN COLORS
// ============================

$pink: #f680ab;
$dark: #272727;

// ============================
// MEDIA
// ============================

$mediaBp1: 1130px;
$mediaBp2: 980px;
$mediaBp3: 767px;
$mediaBp4: 640px;
$mediaBp5: 480px;

// ============================
// MIXINS
// ============================

@mixin flex ($d: flex, $jc: flex-start, $w: nowrap, $ai: stretch, $fd: row) {
	display: $d;
	flex-flow: $fd $w;
	justify-content: $jc;
	align-items: $ai; }

@mixin pulse-bg ($s: 50%, $l: 50%, $t: 50%, $z: 1, $c: #BCE1F4) {
	.pulse-bg {
		width: $s;
		padding-bottom: $s;
		left: $l;
		top: $t;
		z-index: $z;
		color: $c; } }
