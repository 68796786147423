@import "_options";

// ============================
// GENERAL STYLES
// ============================

html, input, textarea, button {
	font-family: "Montserrat";
	color: $dark; }

main {
	overflow: hidden;
	> * {
		padding-top: 70px;
		padding-bottom: 70px; } }

label.error {
	display: none !important; }
input.error {
	border-color: red !important; }

h1, h2, h3, h4 {
	font-weight: 500; }

h2 {
	padding-bottom: 15px;
	margin-bottom: 40px;
	font-size: 30px;
	text-align: center;
	position: relative;
	&:after {
		content: "";
		width: 70px;
		position: absolute;
		left: 50%;
		bottom: 0;
		transform: translate(-50%, 0);
		border-bottom: 2px solid $pink; }
	+ h3 {
		margin: -20px 0 40px;
		font-family: "Montserrat";
		font-weight: normal;
		font-size: 17px;
		text-align: center; } }

.container {
	width: 100%;
	max-width: $mediaBp1;
	padding-left: 10px;
	padding-right: 10px;
	margin-left: auto;
	margin-right: auto; }

.pink-block {
	background: #FBF5F7; }

%for {
	&-slider {
		@include flex($w: wrap, $jc: center);
		margin: -15px;
		&-row {
			padding-left: 80px;
			padding-right: 80px; } }
	&-slide {
		padding: 15px;
		outline-style: none; }
	&-card {
		background: #FFF;
		border: 1px solid #e3e3e3; } }

.slick-slide {
	outline-style: none;
	height: auto;
	> * {
		height: 100%; } }

.slick-track {
	@include flex(); }

.slick-list {
	width: 100%; }

.slick-arrow {
	display: block;
	border: none;
	font-size: 0;
	background: {
		color: transparent;
		repeat: no-repeat;
		position: center;
		size: 23px; }
	cursor: pointer;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	z-index: 2;
	&:before {
		@include flex($jc: center, $ai: center);
		width: 50px;
		height: 50px;
		border-radius: 15px;
		font-family: "icon-font";
		font-size: 16px;
		color: $pink;
		background: #fff;
		border: 1px solid;
		transition: .2s; }
	&:hover:before {
		background: $pink;
		color: #FFF; }
	&.slick-prev {
		left: 10px;
		&:before {
			content: "\e90e"; } }
	&.slick-next {
		right: 10px;
		&:before {
			content: "\e90d"; } } }

.slick-dots {
	@include flex($jc: center, $w: wrap);
	width: 100%;
	margin-top: 25px;
	li {
		background-color: #e9dee2;
		margin: 4px;
		border-radius: 50%;
		&:hover, &.slick-active {
			background-color: $pink; }
		button {
			display: block;
			width: 6px;
			height: 6px;
			background: none;
			border: none;
			overflow: hidden;
			text-indent: -9999px;
			cursor: pointer; } } }

.custom-btn {
	@include flex($jc: center, $ai: center);
	height: 60px;
	overflow: hidden;
	font-size: 17px;
	font-weight: 500;
	color: #fff;
	text-shadow: 0 1px 0px #d25683;
	background: #ffa0c2;
	border-radius: 50px;
	cursor: pointer;
	border: none;
	position: relative;
	box-shadow: inset 0 3px 10px 0 #ffc0e4, 0 3px 0 0 #f786af, 0 12px 21px 0 rgba(#e86594, .35);
	transition: .3s;
	&:hover:before {
		box-shadow: inset 0 3px 100px 5px #ffc0e4; }
	span {
		position: relative;
		z-index: 2; }
	&:before {
		content: "";
		border-radius: 50px;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 1;
		width: 100%;
		height: 100%;
		opacity: 1;
		background: linear-gradient(to bottom, #ffa0c2, #ea5188);
		box-shadow: inset 0 3px 10px 0 #ffc0e4;
		transition: .2s; }
	&:after {
		content: "";
		display: block;
		width: 30px;
		height: 300px;
		margin-left: 60px;
		background: #fff;
		position: absolute;
		left: -40px;
		top: -150px;
		z-index: 1;
		transform: rotate(45deg);
		transition: all 0.1s;
		animation-name: slideme;
		animation-duration: 3s;
		animation-delay: 0.05s;
		animation-timing-function: ease-in-out;
		animation-iteration-count: infinite; }
	[class^="icon-"] {
		display: inline-block;
		margin-right: 10px; } }

@keyframes slideme {
	0% {
		left: -30px;
		margin-left: 0px; }
	30% {
		left: 110%;
		margin-left: 80px; }
	100% {
		left: 110%;
		margin-left: 80px; } }

.pulse-bg {
	position: absolute;
	left: 50%;
	top: 50%;
	z-index: 1;
	transform: translate(-50%, -50%) rotate(45deg);
	width: 50%;
	padding-bottom: 50%;
	color: #e7dee1;
	&-container {
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		animation: pulse infinite ease-in-out 2s; }
	&-circle {
		border: 1px solid;
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%); }
	&-circle1 {
		width: 100%;
		height: 100%; }
	&-circle2 {
		width: 85%;
		height: 85%; }
	&-circle3 {
		width: 70%;
		height: 70%; } }

@keyframes pulse {
	0% {
		transform: scale(.8);
		opacity: 0; }
	50% {
		opacity: 1; }
	to {
		transform: scale(1);
		opacity: 0; } }

@keyframes floating {
	from {
		transform: translate(0, 0px); }
	65% {
		transform: translate(-5px, 10px); }
	to {
		transform: translate(0, -0px); } }

@keyframes floating2 {
	from {
		transform: translate(0, 0px); }
	65% {
		transform: translate(5px, 10px); }
	to {
		transform: translate(0, -0px); } }

@keyframes floating3 {
	from {
		transform: translate(0, 0px); }
	65% {
		transform: translate(0px, 10px); }
	to {
		transform: translate(0, -0px); } }

// ============================
// OTHER STYLES
// ============================

.nav {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 999;
	padding: 5px 0 10px;
	border-bottom: 1px solid #ECECEC;
	background: #fff;
	&-list {
		@include flex($jc: space-around, $ai: center); }
	&__link {
		display: inline-block;
		padding: 5px 10px;
		font-size: 13px;
		color: #353535;
		position: relative;
		border: 2px solid transparent;
		border-radius: 50px;
		&:hover, &.active {
			border-color: $pink; } } }

.header {
	padding: 65px 0 0;
	margin-bottom: 20px;
	&-grid {
		@include flex($jc: space-between, $ai: center); }
	&-col {
		@include flex($ai: center); } }

.logo {
	@include flex($ai: center);
	color: inherit;
	font-size: 13px;
	&__img {
		width: 70px;
		margin-right: 25px; }
	&__text {
		font-size: 18px;
		font-weight: 500;
		margin-bottom: 5px;
		&-sub {
			font-size: 14px;
			line-height: 1.4; } } }

.social {
	@include flex;
	li {
		margin-right: 10px;
		&:last-child {
			margin-right: 0; } }
	&-link {
		@include flex($jc: center, $ai: center);
		width: 53px;
		height: 53px;
		border-radius: 20px;
		font-size: 18px;
		color: #FFF;
		&_viber {
			background-color: #7652AC; }
		&_watsapp {
			background-color: #37B070; } } }

.hotline {
	margin-left: 20px;
	&__num {
		font-size: 24px;
		color: inherit; }
	&__text {
		margin-top: 5px;
		font-size: 14px; } }

.preview {
	&-block {
		padding: 0 0 10px !important;
		@include pulse-bg($s: 90%, $t: 42%, $l: 41%, $z: -1);
		&__title {
			max-width: 640px;
			margin-bottom: 40px;
			font-size: 40px;
			&_mobile {
				@extend .preview-block__title;
				display: none; } }
		&-grid {
			@extend %for-slider;
			align-items: center; }
		&-col {
			@extend %for-slide;
			&:nth-child(1) {
				width: 33%;
				position: relative;
				text-align: center;
				img {
					display: inline-block; } }
			&:nth-child(2) {
				width: 67%; } }
		&__btn {
			width: 260px;
			margin: 30px 0 0; } }
	&-grid {
		@extend %for-slider; }
	&-col {
		@extend %for-slide;
		width: 50%; }
	&-item {
		@include flex($ai: center);
		font-size: 16px;
		line-height: 1.5;
		&__icon {
			margin-right: 20px;
			font-size: 55px;
			color: $pink; } } }

.advantages {
	&-block {
		padding-top: 0;
		position: relative;
		z-index: 2; }
	&-slider {
		@extend %for-slider;
		@extend %for-slider-row;
		@media screen and (min-width: $mediaBp2 + 1) {
			padding: 0; } }
	&-slide {
		@extend %for-slide;
		width: calc(100% / 3); }
	&-item {
		@extend %for-card;
		height: 100%;
		text-align: center;
		&__img {
			height: 240px;
			img {
				display: block;
				width: 100%;
				height: 100%;
				object-fit: cover; } }
		&-descr {
			padding: 15px 30px 20px; }
		&__title {
			font-size: 20px;
			margin-bottom: 10px; }
		&__text {
			font-size: 14px;
			color: #727272; } } }

.catalog {
	&-banner-mobile {
		@media screen and (min-width: $mediaBp2 + 1) {
			display: none; } }
	&-slider {
		@extend %for-slider;
		@extend %for-slider-row;
		@media screen and (min-width: $mediaBp2 + 1) {
			padding: 0; }
		justify-content: flex-start; }
	&-slide {
		@extend %for-slide;
		width: 25%;
		&_banner {
			width: 75%; } }
	&-item {
		@extend %for-card;
		@include flex($fd: column);
		height: 100%;
		cursor: pointer;
		border: 1px solid #e3e3e3;
		transition: .2s;
		&:hover {
			box-shadow: 0 10px 15px 0px rgba($pink, 0.35); }
		&__info-item_hidden {
			display: none; }
		&-banner {
			@include flex($ai: center);
			padding: 0 285px 0 65px;
			height: 100%;
			background: url(../img/banner.jpg) no-repeat center right /cover;
			&__badge {
				@include flex($d: inline-flex, $ai: center);
				padding: 5px 15px;
				margin-bottom: 20px;
				font-size: 17px;
				font-weight: 500;
				color: $pink;
				border: 2px solid;
				border-radius: 50px;
				span {
					font-size: 1.2em;
					margin-right: 5px; } }
			&__title {
				font-size: 20px;
				font-weight: 500;
				margin-bottom: 20px;
				&-sub {
					max-width: 315px;
					color: #727272; } } }
		&__img {
			height: 365px;
			position: relative;
			&-discount {
				@include flex($jc: center, $ai: center);
				width: 43px;
				height: 43px;
				position: absolute;
				top: 10px;
				left: 10px;
				z-index: 5;
				border-radius: 15px;
				font-size: 15px;
				font-weight: 500;
				color: #fff;
				text-shadow: 0 1px 0px #d25683;
				box-shadow: inset 0 3px 10px 0 #ffc0e4, 0 8px 11px 0 rgba(#e86594, .35);
				background: linear-gradient(to bottom, #ffa0c2, #ea5188); }
			img {
				display: block;
				width: 100%;
				height: 100%;
				object-fit: cover; } }
		&-info {
			text-align: center; }
		&-row {
			&_name-case {
				padding: 15px; }
			&_price-btn {
				border-top: 1px solid #e3e3e3;
				padding: 15px 15px 20px; } }
		&__title {
			margin-bottom: 2px;
			font-size: 20px;
			&-sub {
				margin-bottom: 5px;
				font-size: 14px;
				font-weight: normal;
				color: #727272; } }
		&-in-case {
			@include flex($jc: center, $ai: center);
			font-size: 12px;
			color: $pink;
			&__icon {
				font-size: 1.4em;
				margin-right: 5px; } }
		&-price {
			@include flex($jc: center, $ai: center);
			> * {
				margin: 0 5px;
				line-height: 1; }
			&_old {
				font-size: 15px;
				color: #a2a2a2; }
			&_new {
				font-size: 20px; } }
		&__btn {
			max-width: 175px;
			height: 50px;
			margin: 15px auto;
			font-size: 17px; }
		&__more {
			font-size: 12px;
			text-decoration: underline;
			text-decoration-style: dotted; } } }

.hard-choise {
	&-block {
		&-grid {
			@extend %for-slider;
			align-items: center; }
		&-col {
			@extend %for-slide;
			&:nth-child(1) {
				width: 37%;
				position: relative;
				z-index: 1;
				text-align: center;
				img {
					display: inline-block;
					margin: -50px 0 -73px; }
				@include pulse-bg($s: 70%, $l: 52%, $c: #e7dee1, $z: -1); }
			&:nth-child(2) {
				width: 63%; } }
		&__title {
			@media screen and (min-width: $mediaBp2 + 1) {
				text-align: left;
				&:after {
					left: 0;
					transform: translate(0); } }
			&-sub {
				font-size: 16px;
				font-weight: normal;
				margin-bottom: 20px;
				@media screen and (min-width: $mediaBp2 + 1) {
					text-align: left; } } } }
	&-grid {
		@extend %for-slider;
		justify-content: flex-start;
		align-items: center;
		&-text {
			font-size: 14px;
			text-align: center; } }
	&-col {
		@extend %for-slide;
		.hotline {
			margin: 0; } } }

.review {
	&-block {
		&__btn {
			width: 150px;
			height: 48px;
			margin: 40px auto 0;
			font-size: 15px; } }
	&-slider {
		@extend %for-slider;
		@extend %for-slider-row;
		.slick-dots {
			margin-top: 0; } }
	&-slide {
		@extend %for-slide; }
	&-item {
		@extend %for-card;
		display: block;
		width: 100%;
		max-width: 325px;
		margin: auto;
		height: 100%;
		padding: 10px;
		position: relative;
		cursor: pointer;
		&:before {
			content: "\e90c";
			@include flex($jc: center, $ai: center);
			width: 100%;
			height: 100%;
			font-family: "icon-font";
			font-size: 65px;
			color: #FFF;
			position: absolute;
			top: 0;
			left: 0;
			background-color: $pink;
			opacity: 0;
			transition: .2s; }
		&:hover:before {
			opacity: 0.85; }
		&__img {
			width: 100%; } } }

.how-order {
	&-block {
		.preview {
			&-grid {
				width: 100%;
				max-width: 350px;
				margin: -10px auto 30px; }
			&-col {
				width: 100%; }
			&-item {
				font-size: 14px;
				&__icon {
					font-size: 50px; } } } }
	&-slider {
		@extend %for-slider;
		@extend %for-slider-row;
		@media screen and (min-width: $mediaBp2 + 1) {
			padding: 0; } }
	&-slide {
		@extend %for-slide;
		padding-bottom: 30px; }
	&-item {
		@extend %for-card;
		height: auto;
		&__img {
			height: 255px;
			background: #fff9f8;
			img {
				display: block;
				width: 100%;
				height: 100%;
				object-fit: cover; } }
		&-descr {
			padding: 25px 15px;
			text-align: center; }
		&__title {
			font-size: 20px;
			margin-bottom: 10px; }
		&__text {
			font-size: 14px;
			color: #727272;
			a {
				display: inline-block;
				color: inherit;
				&:hover {
					text-decoration: underline; } } }
		&__btn {
			width: 175px;
			height: 50px;
			margin: 15px auto 0;
			font-size: 15px; } } }

.about {
	&-block {
		&-content {
			position: relative;
			margin: 160px 0;
			text-align: center; }
		&__img {
			display: inline-block;
			position: relative;
			text-align: center;
			img {
				display: inline-block; }
			@include pulse-bg($s: 150%, $z: -1, $c: #dfdfdf); }
		&-list {
			li {
				@media screen and (min-width: $mediaBp2 + 1) {
					position: absolute;
					top: -30px;
					&:nth-child(1) {
						left: 10px; }
					&:nth-child(2) {
						right: 10px; } } }
			&-item {
				width: 285px;
				&__icon {
					font-size: 65px;
					color: $pink; }
				&__text {
					margin-top: 25px;
					font-size: 15px; } } } } }

.gallery {
	&-block {
		border-top: 1px solid #ebebeb; }
	&-grid {
		@include flex($w: wrap);
		margin: -2px; }
	&-col {
		width: calc(100% / 6);
		padding: 2px; }
	&-item {
		height: 100%;
		&__img {
			display: block;
			width: 100%;
			height: 100%;
			object-fit: cover;
			transition: .2s;
			filter: brightness(100%);
			&:hover {
				filter: brightness(120%); } } } }

.faq {
	&-grid {
		@extend %for-slider; }
	&-col {
		width: 50%;
		@extend %for-slide; }
	&-item {
		border-radius: 6px;
		background: #FFF;
		color: #000; }
	&__question {
		font-size: 17px;
		padding: 22px 20px;
		padding-right: 50px;
		position: relative;
		cursor: pointer;
		&:after {
			content: "\e908";
			font-family: "icon-font";
			font-size: 16px;
			color: $pink;
			position: absolute;
			right: 20px;
			top: 50%;
			transform: translateY(-50%) rotate(180deg);
			transition: ease .3s; }
		&_active:after {
			transform: translateY(-50%) rotate(0deg); } }
	&__answer {
		display: none;
		padding: 20px 25px;
		padding-top: 10px;
		font-size: 15px; } }

.custom-form {
	width: 100%;
	max-width: 265px;
	// flex-shrink: 0
	margin: auto;
	&-row {
		width: 100%;
		margin-bottom: 10px;
		&:last-child {
			margin-bottom: 0; } }
	&__label {
		display: block;
		font-size: 13px;
		color: #727272;
		margin-bottom: 5px; }
	&__input {
		display: block;
		width: 100%;
		height: 55px;
		border: 2px solid #e3e3e3;
		border-radius: 60px;
		padding: 0 20px 0 50px;
		font-size: 16px;
		background: {
			repeat: no-repeat;
			position: left 20px center;
			size: 18px; }
		&_name {
			background-image: url(../img/svg/006-people.svg); }
		&_phone {
			background-image: url(../img/svg/005-technology-1.svg); } }
	&__btn {
		width: 100%;
		height: 60px;
		font-size: 17px; } }

.footer {
	padding: 45px 0;
	font-size: 13px;
	line-height: 1.5;
	border-top: 1px solid #ebebeb;
	.logo__text-sub {
		font-size: inherit; }
	a {
		color: inherit;
		&:hover {
			text-decoration: underline; } }
	&-grid {
		@include flex($jc: space-between, $ai: center); }
	&__politic {
		text-decoration: underline; } }

.modal-window {
	width: calc(100% - 60px);
	max-width: $mediaBp1;
	padding: 30px 35px 60px;
	margin: 44px 30px;
	position: relative;
	overflow: visible;
	.fancybox-close-small {
		display: block;
		width: 25px;
		height: 25px;
		cursor: pointer;
		background: url(../img/svg/close.svg) no-repeat center;
		border: none;
		position: absolute;
		right: -30px;
		top: -30px;
		overflow: hidden;
		text-indent: -9999px;
		&:before, &:after {
			content: none; } } }

.modal-window {
	&-grid {
		@include flex;
		margin: -20px; }
	&-col {
		padding: 20px;
		&:nth-child(1) {
			width: 40%; }
		&:nth-child(2) {
			width: 60%; } }
	&__title {
		margin: 20px 0 10px;
		font-size: 28px;
		text-align: left;
		&:after {
			content: none; }
		&-sub {
			margin-bottom: 10px;
			font-size: 16px;
			color: #727272;
			text-align: left; } }
	.catalog-item {
		&-in-case {
			justify-content: flex-start;
			margin-bottom: 20px; }
		&-price {
			justify-content: flex-start;
			margin-bottom: 20px;
			&__old {
				font-size: 18px; }
			&__new {
				font-size: 24px;
				color: $dark; } } }
	&-descr {
		@extend %for-card;
		padding: 20px;
		margin-bottom: 30px;
		&-grid {
			@include flex($w: wrap); }
		&-col {
			padding: 20px;
			&:nth-child(1) {
				width: 45%; }
			&:nth-child(2) {
				width: 55%; } }
		&__title {
			font-size: 22px;
			margin-bottom: 20px; }
		&__text {
			font-size: 14px;
			color: #7A7A7A; } } }

.order {
	&-slider {
		width: 100%;
		max-width: 420px;
		margin: auto;
		&__img {
			display: block;
			width: 100%; } }
	&-preview {
		margin-bottom: 6px;
		&__img {
			display: block;
			width: 100%; } }
	&-caption {
		@include flex($w: wrap);
		margin: -3px;
		&-col {
			width: calc(100% / 6);
			padding: 3px; }
		&-item {
			width: 100%;
			padding-bottom: 100%;
			position: relative;
			cursor: pointer;
			img {
				width: 100%;
				height: 100%;
				position: absolute;
				top: 0;
				left: 0;
				object-fit: cover; }
			&_active, &:hover {
				&:after {
					content: "";
					position: absolute;
					top: 0;
					left: 0;
					right: 0;
					bottom: 0;
					border: 2px solid $pink; } } } } }

.fancybox-bg {
	background: $pink; }

.add-questions {
	&-block {}
	&-grid {
		@extend %for-slider;
		justify-content: space-around; }
	&-col {
		@extend %for-slide; }
	&-item {
		font-size: 20px;
		font-weight: 500;
		text-align: center;
		color: inherit;
		&__icon {
			@include flex($jc: center, $ai: center);
			width: 80px;
			height: 80px;
			margin: 0 auto 15px;
			border-radius: 20px;
			font-size: 35px;
			color: #FFF;
			&_watsapp {
				background-color: #43C593; }
			&_viber {
				background-color: #8468D3; }
			&_phone {
				background-color: #F67EAA; } } } }

.thanks-page {
	.preview-block__title, .preview-block__title_mobile {
		@include flex($ai: center);
		max-width: 100%;
		margin-bottom: 20px; }
	.preview-block__title_mobile {
		@media screen and (min-width: $mediaBp2 + 1) {
			display: none; } }
	.preview-block__title {
		&-icon {
			font-size: 1.5em;
			color: $pink;
			margin-right: 20px; }
		&-sub {
			font-size: 16px;
			color: #7A7A7A;
			&_top {
				margin: 45px 0 20px; } } } }

.thanks-social {
	@include flex;
	li {
		margin-right: 20px;
		&:last-child {
			margin-right: 0; } }
	&__link {
		@include flex($jc: center, $ai: center);
		width: 60px;
		height: 60px;
		border-radius: 10px;
		border: 1px solid $pink;
		color: $pink;
		font-size: 23px;
		&:hover {
			background-color: $pink;
			color: #FFF; } } }

// ============================
// MEDIA
// ============================

@media screen and (max-width: $mediaBp1) {
	.container, .modal-window {
		max-width: $mediaBp2; } }

@media screen and (max-width: $mediaBp2) {
	.container, .modal-window {
		max-width: $mediaBp3; }

	.preview {
		&-block {
			&__title {
				display: none;
				&_mobile {
					display: block;
					text-align: center;
					margin-left: auto;
					margin-right: auto; } }
			&__btn {
				margin-left: auto;
				margin-right: auto;
				margin-bottom: 30px; }
			&-col {
				&:nth-child(1) {
					width: 56%;
					@include pulse-bg($s: 90%, $l: 45%, $z: -1);
					img {
						max-width: 100%; } }
				&:nth-child(2) {
					width: 100%; } } } }

	.catalog {
		&-banner-mobile {
			border: 3px solid $pink;
			margin: 30px -10px 0;
			.catalog {
				&-slide_banner {
					width: 100%;
					padding: 0; }
				&-item-banner {
					padding-top: 100px;
					padding-bottom: 100px; } } } }

	.hard-choise {
		&-grid {
			flex-direction: column;
			align-items: center; } }

	.about-block {
		&-content {
			margin: 0; }
		&-list {
			margin-bottom: 70px;
			li {
				margin-bottom: 20px;
				&:last-child {
					margin-bottom: 0; } }
			&-item {
				width: 100%; } }
		&__img {
			@include pulse-bg($s: 100%, $z: -1, $c: #dfdfdf); } }

	.modal-window {
		width: calc(100% - 20px);
		margin: 44px 10px;
		padding: 15px 15px 30px;
		.fancybox-close-small {
			right: 0; }
		.preview-col {
			width: 100%; }
		&-grid {
			margin: -10px; }
		&-col {
			padding: 10px; }
		&-descr {
			padding: 0;
			&-col {
				&:nth-child(n) {
					width: 100%; } } } }

	.thanks-page {
		.preview-block__title:not(.preview-block__title_mobile) {
			display: none; }
		.preview-block__title {
			&-icon {
				margin: 0 0 10px; }
			&-sub {
				text-align: center; } }
		.preview-block__title_mobile {
			flex-direction: column; } }

	.thanks-social {
		justify-content: center; } }

@media screen and (max-width: $mediaBp3) {
	.container, .modal-window {
		max-width: $mediaBp4; }

	.nav {
		display: none; }

	.header {
		padding: 10px 0;
		border-bottom: 1px solid #ebebeb;
		.hotline {
			margin-left: 10px;
			&__text {
				display: none; }
			&__num {
				font-size: 0;
				display: block;
				width: 53px;
				height: 53px;
				border-radius: 20px;
				position: relative;
				overflow: hidden;
				&:before {
					content: "\e907";
					@include flex($jc: center, $ai: center);
					font-family: "icon-font";
					font-size: 18px;
					color: #FFF;
					position: absolute;
					top: 0;
					left: 0;
					right: 0;
					bottom: 0;
					background-color: $pink; } } } }

	.preview-col, .faq-col {
		width: 100%; } }

@media screen and (max-width: $mediaBp4) {
	.container, .modal-window {
		max-width: $mediaBp5; }

	main > * {
		padding-top: 25px;
		padding-bottom: 25px; }

	%for {
		&-slider {
			margin: -7.5px;
			&-row {
				padding-left: 40px;
				padding-right: 40px; } }
		&-slide {
			padding: 7.5px; } }

	.slick-arrow {
		&:before {
			width: 32px;
			height: 32px;
			font-size: 12px;
			border-radius: 10px; }
		&.slick-prev {
			left: 5px; }
		&.slick-next {
			right: 5px; } }

	.custom-btn {
		height: 52px;
		font-size: 14px; }

	.social {
		li {
			margin-right: 5px; }
		&-link {
			width: 45px;
			height: 45px;
			font-size: 18px;
			border-radius: 15px; } }

	.header {
		.logo {
			&__img {
				width: 50px;
				margin-right: 10px; }
			&__text {
				max-width: 50px;
				font-size: 12px;
				&-sub {
					display: none; } } }
		.hotline {
			margin-left: 5px;
			&__num {
				width: 45px;
				height: 45px;
				font-size: 0;
				border-radius: 15px;
				&:before {
					font-size: 18px; } } } }

	.preview {
		&-block {
			&__title {
				font-size: 18px;
				margin-bottom: 15px; } }
		&-item {
			font-size: 12px;
			&__icon {
				font-size: 40px;
				margin-right: 20px; } } }

	.advantages {
		&-item {
			&__img {
				height: 150px; }
			&-descr {
				padding: 15px 15px 20px; }
			&__title {
				font-size: 16px;
				margin-bottom: 5px; }
			&__text {
				font-size: 12px; } } }

	h2 {
		margin-bottom: 20px;
		padding-bottom: 10px;
		font-size: 18px;
		+ h3 {
			margin: -5px auto 15px;
			font-size: 12px; } }

	.catalog {
		&-item {
			width: 100%;
			max-width: 250px;
			margin: auto;
			&__img {
				height: 315px; }
			&__title {
				font-size: 17px;
				&-sub {
					font-size: 12px; } }
			&-in-case {
				font-size: 10px; }
			&-price {
				&_old {
					font-size: 13px; }
				&_new {
					font-size: 17px; } }
			&__btn {
				height: 43px; }
			&__more {
				font-size: 10px; } }
		&-banner-mobile {
			.catalog {
				&-item {
					&-banner {
						background: url(../img/catalog-banner-mob.jpg) no-repeat top center /100%;
						padding: 85% 50px 20px;
						text-align: center;
						&__badge {
							font-size: 14px;
							margin-bottom: 10px; }
						&__title {
							font-size: 16px;
							margin-bottom: 15px;
							&-sub {
								font-size: 13px; } } } } } } }

	.hard-choise {
		&-block {
			&-grid {
				flex-direction: column-reverse; }
			&__title {
				margin-top: 15px;
				&-sub {
					font-size: 12px;
					margin-bottom: 15px; } }
			&-col {
				.hotline {
					&__num {
						font-size: 20px; }
					&__text {
						font-size: 11px; } }
				&:nth-child(2) {
					width: 100%; }
				&:nth-child(1) {
					width: 56%;
					img {
						max-width: 100%;
						margin: 0px 0 -28px; } } } }
		&-grid {
			&-text {
				font-size: 12px;
				p {
					display: inline-block; } } } }

	.how-order {
		&-block {
			.preview {
				&-item {
					font-size: 10px;
					&__icon {
						font-size: 35px;
						margin-right: 15px; } } } }
		&-slider {
			.slick-track {
				align-items: flex-start; } }
		&-item {
			width: 100%;
			max-width: 250px;
			margin: auto;
			&__img {
				height: 220px; }
			&__title {
				font-size: 17px; }
			&__text {
				font-size: 12px; }
			&__btn {
				width: 155px;
				height: 45px; } } }

	.about-block {
		&__img {
			margin-bottom: 60px;
			@include pulse-bg($s: 150%, $z: -1, $c: #dfdfdf);
			img {
				width: 90px; } }
		&-list {
			&-item {
				&__icon {
					font-size: 44px; }
				&__text {
					margin-top: 15px;
					font-size: 11px; } } } }

	.gallery-col {
		width: calc(100% / 3); }

	.faq {
		&__question {
			font-size: 11px;
			padding: 11px 15px;
			&:after {
				font-size: 11px;
				right: 10px; } } }

	.add-questions {
		&-grid {
			flex-direction: column;
			align-items: center; }
		&-col {
			margin-bottom: 20px;
			&:last-child {
				margin-bottom: 0; } }
		&-item {
			&__icon {
				width: 55px;
				height: 55px;
				margin-bottom: 10px;
				font-size: 20px;
				border-radius: 25px; }
			&__text {
				font-size: 14px; } } }

	.footer {
		padding: 25px 0;
		text-align: center;
		font-size: 11px;
		&-grid {
			flex-direction: column; }
		.logo {
			&__img {
				display: none; }
			&__text {
				font-size: 11px; } }
		&__politic {
			display: inline-block;
			margin-bottom: 20px;
			font-size: 11px; } }

	.modal-window {
		&-grid {
			flex-wrap: wrap; }
		&-col {
			&:nth-child(n) {
				width: 100%; } }
		&__title {
			font-size: 18px;
			text-align: center;
			margin: 0 0 5px;
			&-sub {
				font-size: 12px;
				text-align: center; } }
		.catalog-item {
			&-in-case, &-price {
				justify-content: center; } }
		&-descr {
			text-align: center;
			&__title {
				font-size: 18px;
				margin-bottom: 10px; }
			&__text {
				font-size: 12px; } }
		&-politic__text {
			font-size: 13px; } }

	.order {
		&-slider {
			max-width: 320px; } }

	.custom-form {
		&__input {
			font-size: 14px; } } }
